// // Withdraw.js
// import { useState, useEffect } from "react";
// import axios from "axios";

// const Withdraw = () => {
// 	// Mock data for withdrawal requests
// 	const [withdrawData, setWithdrawData] = useState<any>([]);
// 	const [status, setStatus]=useState(false)
// 	  const [filteredWithdrawData, setFilteredWithdrawData] = useState<any[]>([]);
//   const [searchTerm, setSearchTerm] = useState('');

// 		// const email = localStorage.getItem("email");
// 	// useEffect(() => {
// 	// 	const headers = {
// 	// 		"Content-Type": "application/json",
// 	// 		"Access-Control-Allow-Origin": "*",
// 	// 		"Access-Control-Allow-Methods": "*",
// 	// 	};
// 	// 	axios
// 	// 		.get(`https://api.growwincapital.com/api/withdraw/getWithdraw`, {
// 	// 			headers,
// 	// 		})
// 	// 		.then((response) => {
// 	// 			console.log(response.data.Withdraw);
// 	// 			const withdraw: any = Object.values(response.data.Withdraw);
// 	// 			setWithdrawData(withdraw);
// 	// 			// console.log(withdrawAmount);
// 	// 		})
// 	// 		.then((error) => {
// 	// 			console.log("no error " + error);
// 	// 		});
// 	// }, [status]);

// 	  useEffect(() => {
//     const headers = {
//       "Content-Type": "application/json",
//       "Access-Control-Allow-Origin": "*",
//       "Access-Control-Allow-Methods": "*",
//     };

//     axios
//       .get(`https://api.growwincapital.com/api/withdraw/getWithdraw`, { headers })
//       .then((response) => {
//         const withdraw = Object.values(response.data.Withdraw);
//         setWithdrawData(withdraw);
//         setFilteredWithdrawData(withdraw); // Initially, filtered data is the same as the complete data set
//       })
//       .catch((error) => {
//         console.error("Error fetching withdraw data: ", error);
//       });
//   }, []);

//   useEffect(() => {
//     const filteredData = withdrawData.filter((request:any) =>
//       request.email.toLowerCase().includes(searchTerm.toLowerCase())
//     );
//     setFilteredWithdrawData(filteredData);
//   }, [searchTerm, withdrawData]);

//   const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
//     setSearchTerm(event.target.value);
//   };



// 	const rejected = async (id:any,email:any,amount:any) => {
// 		const headers = {
// 			"Content-Type": "application/json",
// 			"Access-Control-Allow-Origin": "*",
// 			"Access-Control-Allow-Methods": "*",
// 		};
// 		const updatedWalletResponse = await axios.get(
// 			`https://api.growwincapital.com/api/wallet/walletbalance/${email}`,
// 			{ headers }
// 		);
// 		const walletAmount = await updatedWalletResponse.data.wallet.balance  
// 		const finalAmount = parseFloat(walletAmount) + parseFloat(amount) ;
// 		const updateAmount = await axios.post(
// 			`https://api.growwincapital.com/api/wallet/updateWallet`,{
// 				email: email,
// 				amount: finalAmount+'',
// 			},
// 			{ headers }
// 		);
// 		console.log(updateAmount);
// 		console.log(walletAmount);

// 		await axios.post(`https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`,{
// 				email: email,
// 				withdrawStatus:"rejected"
// 			},
// 			{ headers }
// 			);
// 			setStatus(true)
// 	};


// 	const hanldeSuccess = async (id: any, email: any) => {
// 		const headers = {
// 			"Content-Type": "application/json",
// 			"Access-Control-Allow-Origin": "*",
// 			"Access-Control-Allow-Methods": "*",
// 		};

// 		const data = await axios.post(`https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`,{
// 				email: email,
// 				withdrawStatus: "Success",
// 			},
// 			{ headers }
// 		);
// 		console.log(data);
// 		setStatus(true);

// 	};



// 	return (
// 		<div className=' '>
// 			<h1 className='text-2xl font-bold mb-4'>Withdrawal Requests</h1>
// 			   <input
//         type="text"
//         placeholder="Search by email..."
//         value={searchTerm}
//         onChange={handleSearchChange}
//         className="mb-4 p-2 border rounded"
//       />
// 			<div className="h-[690px] overflow-scroll">

// 			<table className=' '>
// 				<thead>
// 					<tr>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 							S.No.
// 						</th>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 							User
// 						</th>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 							Amount
// 						</th>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 							Address
// 						</th>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 							Status
// 						</th>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 							Time
// 						</th>
// 						<th className='py-2 px-2 border-b border-gray-200 bg-gray-50 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider'>
// 						    Action
// 						</th>
// 					</tr>
// 				</thead>
// 				<tbody>
// 					{[...filteredWithdrawData].reverse().map((request: any, index: any) => (
// 						<tr key={index + 1}>
// 							<td className='py-2 px-2 border-b border-gray-200'>
// 								{index + 1}
// 							</td>
// 							<td className='py-2 px-2 border-b border-gray-200'>
// 								{request.email}
// 							</td>
// 							<td className='py-2 px-2 border-b font-semibold border-gray-200'>
// 								${Number(parseFloat(request.amount) - parseFloat(request.amount)*5/100).toFixed(2)}
// 							</td>
// 							<td className='py-2 px-2 text-xs border-b border-gray-200 '>
// 								{request.address}
// 								<button
// 									onClick={() =>
// 										navigator.clipboard.writeText(
// 											request.address
// 										)
// 									}
// 									className=' text-xs bg-blue-500 hover:bg-blue-700 text-white font-light py-[2px] px-2 rounded'>
// 									Copy
// 								</button>
// 							</td>
// 							<td className={`py-2 px-2 border-b border-gray-200 ${request.status === "Pending" ? 'text-blue-600' : request.status === "rejected"  ?'text-red-500' :'text-green-600'}`}>
// 								{request.status}
// 							</td>
// 							<td className='py-2 px-2 text-sm border-b border-gray-200'>
// 								{request.createdAt}
// 							</td>

// 							<td className='py-2 px-2 border-b border-gray-200'>
// 								{request.status === "Pending" ? (
// 									<>
// 										<button
// 											onClick={() => hanldeSuccess(request._id,request.email)}
// 											className='text-white bg-green-500 hover:bg-green-600 px-2  py-1 rounded '>
// 											Approve
// 										</button>
// 										<button
// 											onClick={() =>
// 												rejected(
// 													request._id,
// 													request.email,
// 													request.amount
// 												)
// 											}
// 											className='text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded'>
// 											Reject
// 										</button>
// 									</>
// 								) : (
// 									"Done"
// 								)}
// 							</td>
// 						</tr>
// 					))}
// 				</tbody>
// 			</table>
// 			</div>

// 		</div>
// 	);
// };
// export default Withdraw;
// Withdraw.tsx
import React, { useState, useEffect, ChangeEvent } from "react";
import axios from "axios";

interface WithdrawRequest {
  _id: string;
  email: string;
  amount: string;
  address: string;
  status: string;
  createdAt: string;
}

const Withdraw: React.FC = () => {
  const [withdrawData, setWithdrawData] = useState<WithdrawRequest[]>([]);
  const [filteredWithdrawData, setFilteredWithdrawData] = useState<WithdrawRequest[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false); // Loader state for API calls
  const [activeActionId, setActiveActionId] = useState<string | null>(null); // Track the currently active button

  // Fetch withdraw data
  const fetchWithdrawData = async (): Promise<void> => {
    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    try {
      const response = await axios.get(
        `https://api.growwincapital.com/api/withdraw/getWithdraw`,
        { headers }
      );
      const withdraw: WithdrawRequest[] = Object.values(response.data.Withdraw);
      setWithdrawData(withdraw);
      setFilteredWithdrawData(withdraw);
    } catch (error) {
      console.error("Error fetching withdraw data: ", error);
    }
  };

  useEffect(() => {
    fetchWithdrawData();
  }, []);

  useEffect(() => {
    const filteredData = withdrawData.filter((request) =>
      request.email.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredWithdrawData(filteredData);
  }, [searchTerm, withdrawData]);

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setSearchTerm(event.target.value);
  };

  const handleAction = async (
    actionType: "approve" | "reject",
    id: string,
    email: string,
    amount?: string
  ): Promise<void> => {
    setLoading(true);
    setActiveActionId(id);

    const headers = {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
    };

    try {
      if (actionType === "reject" && amount) {
        // Fetch wallet balance and update wallet
        const updatedWalletResponse = await axios.get(
          `https://api.growwincapital.com/api/wallet/walletbalance/${email}`,
          { headers }
        );
        const walletAmount: string = updatedWalletResponse.data.wallet.balance;
        const finalAmount = parseFloat(walletAmount) + parseFloat(amount);
        await axios.post(
          `https://api.growwincapital.com/api/wallet/updateWallet`,
          {
            email: email,
            amount: finalAmount.toString(),
          },
          { headers }
        );
        // Update withdraw status to rejected
        await axios.post(
          `https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`,
          {
            email: email,
            withdrawStatus: "rejected",
          },
          { headers }
        );
      } else if (actionType === "approve") {
        // Update withdraw status to success
        await axios.post(
          `https://api.growwincapital.com/api/withdraw/updateWithdraw/${id}`,
          {
            email: email,
            withdrawStatus: "Success",
          },
          { headers }
        );
      }

      // Refresh data after operation
      await fetchWithdrawData();
    } catch (error) {
      console.error(`Error during ${actionType} operation: `, error);
    } finally {
      setLoading(false);
      setActiveActionId(null);
    }
  };

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">Withdrawal Requests</h1>
      <input
        type="text"
        placeholder="Search by email..."
        value={searchTerm}
        onChange={handleSearchChange}
        className="mb-4 p-2 border rounded"
      />
      <div className="h-[690px] overflow-scroll">
        <table>
          <thead>
            <tr>
              <th>S.No.</th>
              <th>User</th>
              <th>Amount</th>
              <th>Address</th>
              <th>Status</th>
              <th>Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {[...filteredWithdrawData].reverse().map((request, index) => (
              <tr key={request._id}>
                <td>{index + 1}</td>
                <td>{request.email}</td>
                <td>
                  $
                  {(
                    parseFloat(request.amount) -
                    parseFloat(request.amount) * 0.05
                  ).toFixed(2)}
                </td>
                <td>
                  {request.address}
                  <button
                    onClick={() =>
                      navigator.clipboard.writeText(request.address)
                    }
                    className="text-xs bg-blue-500 hover:bg-blue-700 text-white font-light py-[2px] px-2 rounded"
                  >
                    Copy
                  </button>
                </td>
                <td
                  className={
                    request.status === "Pending"
                      ? "text-blue-600"
                      : request.status === "rejected"
                      ? "text-red-500"
                      : "text-green-600"
                  }
                >
                  {request.status}
                </td>
                <td>{request.createdAt}</td>
                <td>
                  {request.status === "Pending" ? (
                    <>
                      <button
                        onClick={() =>
                          handleAction("approve", request._id, request.email)
                        }
                        disabled={loading && activeActionId === request._id}
                        className="text-white bg-green-500 hover:bg-green-600 px-2 py-1 rounded"
                      >
                        {loading && activeActionId === request._id ? (
                          <span>Loading...</span>
                        ) : (
                          "Approve"
                        )}
                      </button>
                      <button
                        onClick={() =>
                          handleAction(
                            "reject",
                            request._id,
                            request.email,
                            request.amount
                          )
                        }
                        disabled={loading && activeActionId === request._id}
                        className="text-white bg-red-500 hover:bg-red-600 px-3 py-1 rounded"
                      >
                        {loading && activeActionId === request._id ? (
                          <span>Loading...</span>
                        ) : (
                          "Reject"
                        )}
                      </button>
                    </>
                  ) : (
                    "Done"
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Withdraw;
